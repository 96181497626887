// @ts-strict-ignore
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MandantClient } from '@shared/models/mandantenClient';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigAssetLoaderService } from './config-asset-loader.service';
import { FileUploadConstants } from '@shared/constants/shared-constants';

@Injectable({
    providedIn: 'root'
})
export class MandantLogoService {
    // Fallback url, falls kein Wappen gefunden wurde
    private wappenFallbackUrl = 'assets/img/k5buergerservice.jpg';

    // Wappen src für die Anzeige im Menü
    private mandantDarstellung: BehaviorSubject<MandantClient.UIInitialisierungMandantDarstellung> =
        new BehaviorSubject<MandantClient.UIInitialisierungMandantDarstellung>({
            uiDarstellung: MandantClient.UiDarstellung.Default,
            nameZeile1: '',
            nameZeile2: '',
            bildContentType: MandantClient.BildContentType.Jpeg,
            bildUi: '',
            bildDataUriPrefix: ''
        });

    mandantDarstellung$: Observable<MandantClient.UIInitialisierungMandantDarstellung> =
        this.mandantDarstellung.asObservable();

    // Id vom aktuellen Mandanten des Benutzers
    initialMandantId: string;

    constructor(
        private http: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {}

    /**
     * Verarbeitet die Logo Daten des Mandanten
     * @param mandantDarstellung Darstellung des Mandanten Logos
     * @returns src für das Wappen Image
     */
    buildWappenSource(mandantDarstellung: MandantClient.BildData): string {
        let wappenSrc = this.wappenFallbackUrl;
        if (mandantDarstellung?.bild) {
            let dataUriPrefix = 'data:image/';
            switch (mandantDarstellung.contentType) {
                case FileUploadConstants.MIME_TYPE_JPEG:
                    dataUriPrefix += 'jpeg';
                    break;
                case FileUploadConstants.MIME_TYPE_JPG:
                    dataUriPrefix += 'jpeg';
                    break;
                case FileUploadConstants.MIME_TYPE_PNG:
                    dataUriPrefix += 'png';
                    break;
                case FileUploadConstants.MIME_TYPE_SVG:
                    dataUriPrefix += 'svg+xml';
                    break;
                default:
                    return '';
            }
            dataUriPrefix += ';base64,';
            wappenSrc = dataUriPrefix + mandantDarstellung.bild;
        }
        return wappenSrc;
    }

    /**
     * Updated die src des Wappens im Menü
     */
    updateMandantDarstellung(mandantDarstellung: MandantClient.UIInitialisierungMandantDarstellung): void {
        this.mandantDarstellung.next(mandantDarstellung);
    }

    /**
     * Speichert die Darstellung eines Mandanten inklusive Logo
     * @param mandantDarstellung Darstellung des Mandanten
     * @returns
     */
    postMandantDarstellung(
        mandantDarstellung: MandantClient.SaveMandantDarstellung,
        mandantId: string
    ): Observable<MandantClient.MandantDarstellung> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'k5-mandantid': mandantId
            })
        };
        return this.http.post<MandantClient.MandantDarstellung>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi}/Mandant/Darstellung`,
            mandantDarstellung,
            options
        );
    }

    /**
     * Gibt die Darstellung eines Mandanten zurück
     * @param mandantId ID des Mandanten dessen Details gelesen werden sollen
     */
    getMandantDarstellung(mandantId: string): Observable<MandantClient.MandantDarstellung> {
        return this.http.get<MandantClient.MandantDarstellung>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi}/Mandant/Darstellung?mandantId=${mandantId}`
        );
    }
}
