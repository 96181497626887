// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Theme, THEMES } from '../models/theme';

/**
 * Service für die Anpassung des Themes
 */
@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private themeKey = 'theme';
    private themes: Theme[];
    private theme = new BehaviorSubject<Theme>({
        name: 'No Theme', label: 'No Theme',
        materialClassName: 'No Theme', isDarkMode: false, properties: {}
    });
    public theme$ = this.theme.asObservable();

    constructor() {
        this.initializeThemes();
    }

    /**
     * Initialisiert alle vorhandenen Themes und speichert das Light-Theme im SessionStorage
     */
    initializeThemes() {
        this.themes = this.getThemes();
        const themeName = sessionStorage.getItem(this.themeKey);

        let theme = this.themes.find(t => t.name === themeName);
        if (!theme) { theme = this.themes[0]; }

        sessionStorage.setItem(this.themeKey, theme.name);
        this.setTheme(theme);
    }

    /**
     * Gibt alle vorhandenen Themes zurück
     * @returns Theme[]
     */
    getThemes(): Theme[] {
        return THEMES;
    }

    /**
     * Liefert die Namen aller Themes zurück
     * Duplikate werden nur einmal in das Resultat aufgenommen
     * @returns Eindeutige Labels aller Themes
     */
    getThemeLabels(): string[] {
        return [...new Set(this.getThemes().map(theme => theme.label))];
    }

    /**
     * Gibt das aktuelle Theme zurück
     * @returns Theme
     */
    getCurrentTheme(): Theme {
        return this.theme.value;
    }

    /**
     * Tauscht das aktuelle Theme gegen ein neues Theme aus
     * @param theme neues Theme
     */
    setTheme(theme: Theme): void {
        sessionStorage.setItem(this.themeKey, theme.name);
        if (theme === this.theme.value) { return; }
        this.updateTheme(theme);
        this.theme.next(theme);
    }

    /**
     * Tauscht das aktuelle Theme gegen ein neues Theme das dem übergebenen Namen entspricht aus.
     * @param name Name des neu gewählten Theme
     */
    setThemeByName(name: string): void {
        if (name) {
            const newTheme = this.getThemes().find(themes => themes.name === name);

            (newTheme) ?
                // Falls ein Wert gefunden wurde das neue Theme sezten
                this.setTheme(newTheme) :
                // andernfalls das Default Theme verweden
                this.setTheme(this.getThemes().find(themes => themes.name === 'lightPetrol'));

        } else {
            // Fall kein Name für ein Theme übergeben wurde ebenfalls das Standardtheme setzen
            this.setTheme(this.getThemes().find(themes => themes.name === 'lightPetrol'));
        }
    }

    /**
     * Sucht ein Theme nach der Bezeichnung und dem DarkMode-Flag
     * @param label Label des Themes
     * @param isDarkMode Flag für den DarkMode
     * @returns Gefundenes Theme nach Kriterien oder das aktuelle Theme, wenn keines gefunden wurde
     */
    findThemeByLabelAndDarkModeFlag(label: string, isDarkMode: boolean): Theme {

        const theme = this.getThemes().find(theme => theme.label === label && theme.isDarkMode === isDarkMode);
        if (theme) {
            return theme;
        }

        return this.getCurrentTheme();
    }

    /**
     * Ändert die Primärfarbe des aktuellen Themes
     * @param value Farbcode
     */
    setPrimaryColor(value: string): void {
        document.documentElement.style.setProperty('--color-primary', value);
    }

    /**
     * Schaltet zwischen hellen und dunklem Farbschema um
     * @param slideToggleChange neuer toggle Status
     */
    toggleDarkMode(checked: boolean) {
        const currentTheme = this.getCurrentTheme();
        const theme = checked ? this.themes.find(x =>
            x.isDarkMode && x.label === currentTheme.label) : this.themes.find(x => !x.isDarkMode && x.label === currentTheme.label);
        sessionStorage.setItem(this.themeKey, theme.name);

        this.setTheme(theme);
    }

    /**
     * Setzt alle CSS-Variablen auf das neue Theme
     * @param theme neues Theme
     */
    updateTheme(theme: Theme): void {
        for (const field in theme.properties) {
            if (theme.properties.hasOwnProperty(field)) {
                document.documentElement.style.setProperty(field, theme.properties[field]);
            }
        }
    }

    getPrimaryColor(): string {
        return document.documentElement.style.getPropertyValue('--color-primary');
    }

    getAllColors(): string[] {
        const colors = Object.values<string>(this.theme.value.properties).slice(0, 14);
        return colors;
    }
}
