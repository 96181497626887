/**
 * Interface for a theme
 */
export interface Theme {
    /**
     * Wird im Mandantenservice gespeichert und ist auch der
     * Klassenname im CSS für das Angular-Material Theme
     */
    name: string;
    label: string;
    materialClassName: string;
    isDarkMode: boolean;

    /**
     * Farben, die als CSS-Variablen im :root gesetzt werden.
     */
    properties: { [key: string]: string };
}

/**
 * Farbabstufungen von https://material.io/resources/color/#!/?view.left=0&view.right=0
 * Wenn die Farbwerte hier verändert werden, müssen diese auch im Theme für Angular Material
 * verändert werden. Diese befinden sich in der Datei: _default.scss
 */

const LIGHT_PETROL: Theme = {
    name: 'lightPetrol',
    label: 'Petrol',
    materialClassName: 'mat-custom-petrol',
    isDarkMode: false,
    properties: {
        '--color-primary': '#186B80',
        '--color-primary-50': '#E3EDF0',
        '--color-primary-100': '#BAD3D9',
        '--color-primary-200': '#8CB5C0',
        '--color-primary-300': '#5D97A6',
        '--color-primary-400': '#3B8193',
        '--color-primary-600': '#156378',
        '--color-primary-700': '#11586D',
        '--color-primary-800': '#0E4E63',
        '--color-primary-900': '#083C50',
        '--color-primary-A100': '#3AF9FF',
        '--color-primary-A200': '#00F4FC',
        '--color-primary-A400': '#00DBE2',
        '--color-primary-A700': '#00CDD3',
        '--color-nav-background': 'white',
        '--color-nav-foreground': 'black',
        '--color-nav-icon-alt': '#6c757d',
        '--color-navItem-hover': 'whitesmoke',
        '--color-navItem-active': 'lightgray',
        '--color-navItem-active-background': 'var(--color-primary-50)',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'var(--color-primary)',

        '--shadow-nav': '4px 0px 10px 0px rgba(0,0,0,.2)'
    }
};

const LIGHT_PURPLE: Theme = {
    name: 'lightPurple',
    label: 'Violett',
    materialClassName: 'mat-custom-purple',
    isDarkMode: false,
    properties: {
        '--color-primary': '#9C27B0',
        '--color-primary-50': '#f3e5f5',
        '--color-primary-100': '#e1bee7',
        '--color-primary-200': '#ce93d8',
        '--color-primary-300': '#ba68c8',
        '--color-primary-400': '#ab47bc',
        '--color-primary-600': '#8e24aa',
        '--color-primary-700': '#7b1fa2',
        '--color-primary-800': '#6a1b9a',
        '--color-primary-900': '#4a148c',
        '--color-primary-A100': '#ea80fc',
        '--color-primary-A200': '#e040fb',
        '--color-primary-A400': '#d500f9',
        '--color-primary-A700': '#aa00ff',
        '--color-nav-background': 'white',
        '--color-nav-foreground': 'black',
        '--color-nav-icon-alt': '#6c757d',
        '--color-navItem-hover': 'whitesmoke',
        '--color-navItem-active': 'lightgray',
        '--color-navItem-active-background': '#f3e5f5',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'var(--color-primary)',

        '--shadow-nav': '4px 0px 10px 0px rgba(0,0,0,.2)'
    }
};

const LIGHT_ORANGE: Theme = {
    name: 'lightOrange',
    label: 'Orange',
    materialClassName: 'mat-custom-orange',
    isDarkMode: false,
    properties: {
        '--color-primary': '#FF5722',
        '--color-primary-50': '#fbe9e7',
        '--color-primary-100': '#ffccbc',
        '--color-primary-200': '#ffab91',
        '--color-primary-300': '#ff8a65',
        '--color-primary-400': '#ff7043',
        '--color-primary-600': '#f4511e',
        '--color-primary-700': '#e64a19',
        '--color-primary-800': '#d84315',
        '--color-primary-900': '#bf360c',
        '--color-primary-A100': '#ff9e80',
        '--color-primary-A200': '#ff6e40',
        '--color-primary-A400': '#ff3d00',
        '--color-primary-A700': '#dd2c00',
        '--color-nav-background': 'white',
        '--color-nav-foreground': 'black',
        '--color-nav-icon-alt': '#6c757d',
        '--color-navItem-hover': 'whitesmoke',
        '--color-navItem-active': 'lightgray',
        '--color-navItem-active-background': 'var(--color-primary-50)',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'var(--color-primary)',

        '--shadow-nav': '4px 0px 10px 0px rgba(0,0,0,.2)'
    }
};

const LIGHT_GREEN: Theme = {
    name: 'lightGreen',
    label: 'Green',
    materialClassName: 'mat-custom-green',
    isDarkMode: false,
    properties: {
        '--color-primary': '#4CAF50',
        '--color-primary-50': '#e8f5e9',
        '--color-primary-100': '#c8e6c9',
        '--color-primary-200': '#a5d6a7',
        '--color-primary-300': '#81c784',
        '--color-primary-400': '#66bb6a',
        '--color-primary-600': '#43a047',
        '--color-primary-700': '#388e3c',
        '--color-primary-800': '#2e7d32',
        '--color-primary-900': '#1b5e20',
        '--color-primary-A100': '#b9f6ca',
        '--color-primary-A200': '#69f0ae',
        '--color-primary-A400': '#00e676',
        '--color-primary-A700': '#00c853',
        '--color-nav-background': 'white',
        '--color-nav-foreground': 'black',
        '--color-nav-icon-alt': '#6c757d',
        '--color-navItem-hover': 'whitesmoke',
        '--color-navItem-active': 'lightgray',
        '--color-navItem-active-background': 'var(--color-primary-50)',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'var(--color-primary)',

        '--shadow-nav': '4px 0px 10px 0px rgba(0,0,0,.2)'
    }
};
const LIGHT_CYAN: Theme = {
    name: 'lightCyan',
    label: 'Cyan',
    materialClassName: 'mat-custom-cyan',
    isDarkMode: false,
    properties: {
        '--color-primary': '#00BCD4',
        '--color-primary-50': '#e0f7fa',
        '--color-primary-100': '#b2ebf2',
        '--color-primary-200': '#80deea',
        '--color-primary-300': '#4dd0e1',
        '--color-primary-400': '#26c6da',
        '--color-primary-600': '#00acc1',
        '--color-primary-700': '#0097a7',
        '--color-primary-800': '#00838f',
        '--color-primary-900': '#006064',
        '--color-primary-A100': '#84ffff',
        '--color-primary-A200': '#18ffff',
        '--color-primary-A400': '#00e5ff',
        '--color-primary-A700': '#00b8d4',
        '--color-nav-background': 'white',
        '--color-nav-foreground': 'black',
        '--color-nav-icon-alt': '#6c757d',
        '--color-navItem-hover': 'whitesmoke',
        '--color-navItem-active': 'lightgray',
        '--color-navItem-active-background': 'var(--color-primary-50)',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'var(--color-primary)',

        '--shadow-nav': '4px 0px 10px 0px rgba(0,0,0,.2)'
    }
};



const DARK_PETROL: Theme = {
    name: 'darkPetrol',
    label: 'Petrol',
    materialClassName: 'mat-custom-petrol',
    isDarkMode: true,
    properties: {
        '--color-primary': '#186B80',
        '--color-primary-50': '#E3EDF0',
        '--color-primary-100': '#BAD3D9',
        '--color-primary-200': '#8CB5C0',
        '--color-primary-300': '#5D97A6',
        '--color-primary-400': '#3B8193',
        '--color-primary-600': '#156378',
        '--color-primary-700': '#11586D',
        '--color-primary-800': '#0E4E63',
        '--color-primary-900': '#083C50',
        '--color-primary-A100': '#3AF9FF',
        '--color-primary-A200': '#00F4FC',
        '--color-primary-A400': '#00DBE2',
        '--color-primary-A700': '#00CDD3',
        '--color-nav-background': '#25292b',
        '--color-nav-foreground': 'whitesmoke',
        '--color-nav-icon-alt': 'whitesmoke',
        '--color-navItem-hover': '#33383b',
        '--color-navItem-active': '#40474a',
        '--color-navItem-active-background': '#D1E2E622',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'white',

        '--shadow-nav': 'inset -8px 0px 10px -6px rgba(0,0,0,.4)'
    }
};

const DARK_PURPLE: Theme = {
    name: 'darkPurple',
    label: 'Violett',
    materialClassName: 'mat-custom-purple',
    isDarkMode: true,
    properties: {
        '--color-primary': '#9C27B0',
        '--color-primary-50': '#f3e5f5',
        '--color-primary-100': '#e1bee7',
        '--color-primary-200': '#ce93d8',
        '--color-primary-300': '#ba68c8',
        '--color-primary-400': '#ab47bc',
        '--color-primary-600': '#8e24aa',
        '--color-primary-700': '#7b1fa2',
        '--color-primary-800': '#6a1b9a',
        '--color-primary-900': '#4a148c',
        '--color-primary-A100': '#ea80fc',
        '--color-primary-A200': '#e040fb',
        '--color-primary-A400': '#d500f9',
        '--color-primary-A700': '#aa00ff',
        '--color-nav-background': '#25292b',
        '--color-nav-foreground': 'whitesmoke',
        '--color-nav-icon-alt': 'whitesmoke',
        '--color-navItem-hover': '#33383b',
        '--color-navItem-active': '#40474a',
        '--color-navItem-active-background': '#e1bee766',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'white',

        '--shadow-nav': 'inset -8px 0px 10px -6px rgba(0,0,0,.4)'
    }
};

const DARK_ORANGE: Theme = {
    name: 'darkOrange',
    label: 'Orange',
    materialClassName: 'mat-custom-orange',
    isDarkMode: true,
    properties: {
        '--color-primary': '#FF5722',
        '--color-primary-50': '#fbe9e7',
        '--color-primary-100': '#ffccbc',
        '--color-primary-200': '#ffab91',
        '--color-primary-300': '#ff8a65',
        '--color-primary-400': '#ff7043',
        '--color-primary-600': '#f4511e',
        '--color-primary-700': '#e64a19',
        '--color-primary-800': '#d84315',
        '--color-primary-900': '#bf360c',
        '--color-primary-A100': '#ff9e80',
        '--color-primary-A200': '#ff6e40',
        '--color-primary-A400': '#ff3d00',
        '--color-primary-A700': '#dd2c00',
        '--color-nav-background': '#25292b',
        '--color-nav-foreground': 'whitesmoke',
        '--color-nav-icon-alt': 'whitesmoke',
        '--color-navItem-hover': '#33383b',
        '--color-navItem-active': '#40474a',
        '--color-navItem-active-background': '#ffccbc66',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'white',

        '--shadow-nav': 'inset -8px 0px 10px -6px rgba(0,0,0,.4)'
    }
};

const DARK_GREEN: Theme = {
    name: 'darkGreen',
    label: 'Green',
    materialClassName: 'mat-custom-green',
    isDarkMode: true,
    properties: {
        '--color-primary': '#4CAF50',
        '--color-primary-50': '#e8f5e9',
        '--color-primary-100': '#c8e6c9',
        '--color-primary-200': '#a5d6a7',
        '--color-primary-300': '#81c784',
        '--color-primary-400': '#66bb6a',
        '--color-primary-600': '#43a047',
        '--color-primary-700': '#388e3c',
        '--color-primary-800': '#2e7d32',
        '--color-primary-900': '#1b5e20',
        '--color-primary-A100': '#b9f6ca',
        '--color-primary-A200': '#69f0ae',
        '--color-primary-A400': '#00e676',
        '--color-primary-A700': '#00c853',
        '--color-nav-background': '#25292b',
        '--color-nav-foreground': 'whitesmoke',
        '--color-nav-icon-alt': 'whitesmoke',
        '--color-navItem-hover': '#33383b',
        '--color-navItem-active': '#40474a',
        '--color-navItem-active-background': '#c8e6c966',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'white',

        '--shadow-nav': 'inset -8px 0px 10px -6px rgba(0,0,0,.4)'
    }
};
const DARK_CYAN: Theme = {
    name: 'darkCyan',
    label: 'Cyan',
    materialClassName: 'mat-custom-cyan',
    isDarkMode: true,
    properties: {
        '--color-primary': '#00BCD4',
        '--color-primary-50': '#e0f7fa',
        '--color-primary-100': '#b2ebf2',
        '--color-primary-200': '#80deea',
        '--color-primary-300': '#4dd0e1',
        '--color-primary-400': '#26c6da',
        '--color-primary-600': '#00acc1',
        '--color-primary-700': '#0097a7',
        '--color-primary-800': '#00838f',
        '--color-primary-900': '#006064',
        '--color-primary-A100': '#84ffff',
        '--color-primary-A200': '#18ffff',
        '--color-primary-A400': '#00e5ff',
        '--color-primary-A700': '#00b8d4',
        '--color-nav-background': '#25292b',
        '--color-nav-foreground': 'whitesmoke',
        '--color-nav-icon-alt': 'whitesmoke',
        '--color-navItem-hover': '#33383b',
        '--color-navItem-active': '#40474a',
        '--color-navItem-active-background': '#b2ebf266',
        '--color-table-hover': 'var(--color-primary-100)',
        '--color-table-select': 'var(--color-primary-50)',
        '--color-navItem-active-icon': 'white',

        '--shadow-nav': 'inset -8px 0px 10px -6px rgba(0,0,0,.4)'
    }
};

/**
 * All available themes
 */
export const THEMES = [
    LIGHT_PETROL,
    DARK_PETROL,
    LIGHT_ORANGE,
    DARK_ORANGE,
    LIGHT_CYAN,
    DARK_CYAN,
    LIGHT_GREEN,
    DARK_GREEN
];
